.wog-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.wog-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wog-flex-col {
  flex-direction: column;
}

.wog-card {
  background: azure;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
}
