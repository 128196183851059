.color-grad {
  background: rgba(32, 124, 229, 0);
  background: -moz-linear-gradient(top, rgba(32, 124, 229, 0) 0%, rgba(163, 208, 250, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(32, 124, 229, 0)), color-stop(100%, rgba(163, 208, 250, 1)));
  background: -webkit-linear-gradient(top, rgba(32, 124, 229, 0) 0%, rgba(163, 208, 250, 1) 100%);
  background: -o-linear-gradient(top, rgba(32, 124, 229, 0) 0%, rgba(163, 208, 250, 1) 100%);
  background: -ms-linear-gradient(top, rgba(32, 124, 229, 0) 0%, rgba(163, 208, 250, 1) 100%);
  background: linear-gradient(to bottom, rgba(32, 124, 229, 0) 0%, rgba(163, 208, 250, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#207ce5', endColorstr='#a3d0fa', GradientType=0);
  height: 70px;
}


.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.container-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 700px) {
  .container-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
